<template>
  <div class="">
    <div class="block">
      <b-button
        class="is-fullwidth"
        type="is-primary"
        @click="isCreatorAcitve = true"
        rounded
        >DODAJ USŁUGĘ</b-button
      >
    </div>
    <div class="block" v-if="error">
      <b-message title="Błąd" type="is-danger" aria-close-label="Close message">
        {{ content }}
      </b-message>
    </div>
    <b-modal
      v-model="isCreatorAcitve"
      class="tile is-12 service-creator"
      has-modal-card
      full-screen
      :can-cancel="['escape', 'x', 'outside']"
    >
      <section>
        <div class="modal-card is-fullwidth" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Kreator tworzenia usług</p>
          </header>
          <section class="modal-card-body">
            <div class="tile is-12 is-vertical">
              <div class="tile">
                <b-field label="Wybierz rodzaj nowo tworzonych usług">
                  <b-select v-model="chosenType">
                    <option
                      v-for="(type, index) in types"
                      :value="type.id"
                      :key="index"
                    >
                      {{ type.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div
                class="tile"
                v-for="(subservice, index2) in subservices"
                :key="index2"
              >
                <div class="box half-box mt-2">
                  <div class="columns is-desktop is-multiline">
                    <div class="column is-3 mt-2">
                      <b-field label="Nazwa usługi">
                        <b-input
                          size="is-small"
                          rounded
                          v-model="subservice.name"
                        ></b-input>
                      </b-field>
                    </div>

                    <div class="column is-two-third mt-2">
                      <b-field label="Opis">
                        <b-input
                          size="is-small"
                          rounded
                          v-model="subservice.description"
                        ></b-input>
                      </b-field>
                    </div>

                    <div class="column is-one-fifth mt-2">
                      <b-field
                        label="Typ (ile wariantów można wybrać w zamówieniu)"
                      >
                        <b-radio
                          v-model="subservice.type"
                          name="type"
                          value="radio"
                        >
                          Jedno
                        </b-radio>
                        <b-radio
                          v-model="subservice.type"
                          name="type"
                          value="checkbox"
                        >
                          Wiele
                        </b-radio>
                      </b-field>
                    </div>

                    <div class="column is-one-fifth mt-2">
                      <b-field
                        label="Czy możliwe jest załączanie zdjęć do zamówienia?"
                      >
                        <b-checkbox v-model="subservice.allow_photos">
                          {{ subservice.allow_photos ? "TAK" : "NIE" }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                  <div
                    class="tile is-vertical is-parent"
                    v-if="subservice.services && subservice.services.length > 0"
                  >
                    <div
                      class="tile is-child service-box is-desktop box"
                      v-for="(service, index3) in subservice.services"
                      :key="index3"
                    >
                      <div class="tile is-parent is-vertical mt-2">
                        <div class="columns tile is-child">
                          <div class="column is-4">
                            <b-field label="Nazwa wariantu">
                              <b-input
                                size="is-small"
                                rounded
                                v-model="service.name"
                              ></b-input>
                            </b-field>
                          </div>
                          <div class="column is-6">
                            <b-field label="Opis wariantu">
                              <b-input
                                size="is-small"
                                rounded
                                v-model="service.description"
                              ></b-input>
                            </b-field>
                          </div>
                        </div>
                        <div class="columns tile is-child">
                          <div class="column is-3">
                            <b-field label="Cena wariantu (zł)">
                              <b-input
                                size="is-small"
                                rounded
                                v-model="service.value"
                                type="number"
                              >
                              </b-input>
                            </b-field>
                          </div>
                          <div class="column is-3">
                            <b-field label="Czas trwania (dni robocze)">
                              <b-input
                                size="is-small"
                                rounded
                                v-model="service.workdays"
                                type="number"
                              >
                              </b-input>
                            </b-field>
                          </div>
                          <div class="column is-5">
                            <b-field label="Wielokrotność usługi w zamówieniu?">
                              <b-checkbox v-model="service.enable_counter">
                                {{ service.enable_counter ? "TAK" : "NIE" }}
                              </b-checkbox>
                            </b-field>
                          </div>
                        </div>
                        <div class="level delete-variant">
                          <div class="level-right">
                            <b-field>
                              <b-button
                                rounded
                                class="button expanded is-small is-danger is-light modal-button"
                                @click="deleteService(index2, index3)"
                              >
                                Usuń wariant
                              </b-button>
                            </b-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tile level">
                    <b-field class="level-left">
                      <b-button
                        rounded
                        class="mt-6 button expanded is-small is-primary is-light modal-button"
                        @click="addNewService(subservice)"
                        >Dodaj wariant dla usługi</b-button
                      >
                    </b-field>
                    <b-field class="level-right">
                      <b-button
                        rounded
                        class="mt-6 button expanded is-small is-danger is-light modal-button"
                        @click="deleteSubservice(index2)"
                        >Usuń usługę</b-button
                      >
                    </b-field>
                  </div>
                </div>
              </div>

              <div class="tile">
                <b-field>
                  <b-button
                    rounded
                    class="mt-6 button expanded is-small is-primary modal-button"
                    @click="addNewSubservice()"
                    >Dodaj usługę</b-button
                  >
                </b-field>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button
              rounded
              label="Zamknij"
              type="is-dark"
              @click="isCreatorAcitve = false"
            />
            <b-button
              rounded
              label="Zapisz"
              @click="addServices()"
              type="is-primary"
            />
          </footer>
        </div>
      </section>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isCreatorAcitve: false,
      editorServices: {},
      subservices: [],
      chosenType: 1,
      error: false,
      content: "",
    };
  },

  methods: {
    addNewSubservice: function () {
      this.subservices.push({
        name: "",
        description: "",
        order: this.subservices.length + 1 || 0,
        type: "radio",
        allow_photos: false,
        services: [],
      });
    },
    addNewService: function (subservice) {
      subservice.services.push({
        name: "",
        description: "",
        value: "",
        workdays: "",
        order: subservice.length + 1 || 0,
        enable_counter: false,
      });
    },

    deleteSubservice: function (index) {
      this.subservices.splice(index, 1);
    },
    deleteService: function (indexSubservice, indexService) {
      this.subservices[indexSubservice].services.splice(indexService, 1);
    },

    addServices: function () {
      //TODO WALIDACJA
      let payload = {
        type_id: this.chosenType,
        services: this.subservices,
        id: this.companyId,
        token: this.token,
      };

      this.$store
        .dispatch("serviceCreator/addServices", payload)
        .then((response) => {
          if (response == true) {
            this.subservices = [];
            this.isCreatorAcitve = false;
          } else {
            this.isCreatorAcitve = false;
            this.error = true;
            this.content =
              "Błąd dodawania usług. Jeśli będzie się to powtarzać zgłoś ten fakt do administratora.";
          }
        });
    },
  },

  created() {},

  computed: {
    token: {
      get() {
        return this.$store.state.auth.token;
      },
    },
    companyId: {
      get() {
        return this.$store.state.auth.id;
      },
    },
    ///  services() {
    //   return this.$store.getters["companyServices/services"];
    //  },
    types: {
      get() {
        return this.$store.state.serviceCreator.types;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.select {
  width: 50%;
}
.modal-button {
  width: 250px;
}

.half-box {
  width: 80%;
}

.service-box {
  position: relative;
  background-color: #eee;
  .column {
    height: 64px;
  }
  .modal-button {
    width: 125px;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
}

.service-creator{
  width:100%;
  height:100%;
background-color: #fff!important;

}

.service-creator .modal-background {
    background-color: #fff!important;
}

</style>