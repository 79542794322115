<template>
  <div class="card">
    <header class="card-header" color="is-primary">
      <p class="card-header-title">{{ service.name }}</p>
    </header>
    <div class="card-content">
      <div class="content">
        <div class="tile is-vertical">
          <div class="tile">
            <strong>Opis</strong>: {{ service.description }}
          </div>
          <div class="tile">
            <strong class="mr-2">Dodanie zdjęcia:</strong
            >{{ service.allow_photos ? "TAK" : "NIE" }}
          </div>
          <div class="tile">
            <strong class="mr-2">Wybór wariantów:</strong
            >{{ service.type == "radio" ? "Jeden" : "Wiele" }}
          </div>
        </div>
      </div>
      <div class="content">
        <strong>Warianty:</strong>
        <div
          class="tile is-vertical mb-2 box"
          v-for="(s, index) in service.services"
          :key="index"
        >
          <div class="tile">
            <strong class="">{{ s.name }}</strong>
          </div>
          <div class="tile" v-if="s.description">
            <span class="is-grey">
              <strong>Opis</strong>: {{ s.description }}
            </span>
          </div>
          <div class="tile" v-if="s.working_days">
            <span class="is-grey">
              <strong>Przewidywane dni robocze</strong>: {{ s.working_days }}
            </span>
          </div>
          <div class="tile">
            <strong
              ><b-tag type="is-primary">{{ s.value }} zł</b-tag></strong
            >
          </div>
          <div class="tile mt-6 level level-right">
            <b-button size="is-small" type="is-white" @click="editVariant(s)">
              Edytuj
            </b-button>
            <b-button size="is-small" type="is-dark " @click="deleteVariant(s)">
              Usuń
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <a class="card-footer-item" @click="addVariant(service)">Dodaj wariant</a>
      <a class="card-footer-item" @click="removeSubService(service.id)"
        >Usuń usługę</a
      >
    </footer>
    <!--ADD WARIANT-->
    <b-modal
      v-model="isCreatorVariantActive"
      :can-cancel="['escape', 'x', 'outside']"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      aria-modal
    >
      <form action="" v-if="variant">
        <div class="container box">
          <h3>Dodaj wariant usługi</h3>
          <b-field label="Nazwa">
            <b-input v-model="variant.name" maxlength="30"> </b-input>
          </b-field>
          <b-field label="Opis">
            <b-input v-model="variant.description" maxlength="100"> </b-input>
          </b-field>
          <b-field label="Cena">
            <b-input v-model="variant.value" type="number" maxlength="30">
            </b-input>
          </b-field>
          <b-field label="Czas trwania">
            <b-input
              v-model="variant.working_days"
              type="number"
              maxlength="30"
            >
            </b-input>
          </b-field>
          <b-field label="Wielokrotność usługi w zamówieniu?">
            <b-checkbox v-model="variant.enable_counter">
              {{ variant.enable_counter ? "TAK" : "NIE" }}
            </b-checkbox>
          </b-field>
          <b-button
            type="is-primary"
            class="mr-3"
            rounded
            @click="addVariantService()"
          >
            Zapisz
          </b-button>
          <b-button
            type="is-dark"
            rounded
            @click="isCreatorVariantActive = false"
          >
            Anuluj
          </b-button>
        </div>
      </form>
    </b-modal>
    <!--EDIT WARIANT-->

    <b-modal
      v-model="isEditVariantActive"
      :can-cancel="['escape', 'x', 'outside']"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      aria-modal
    >
      <form action="" v-if="variant">
        <div class="container box">
          <h3>Edycja wariantu usługi</h3>
          <b-field label="Nazwa">
            <b-input v-model="variant.name" maxlength="30"> </b-input>
          </b-field>
          <b-field label="Opis">
            <b-input v-model="variant.description" maxlength="100"> </b-input>
          </b-field>
          <b-field label="Cena">
            <b-input v-model="variant.value" type="number" maxlength="30">
            </b-input>
          </b-field>
          <b-field label="Czas trwania">
            <b-input
              v-model="variant.working_days"
              type="number"
              maxlength="30"
            >
            </b-input>
          </b-field>
          <b-field label="Wielokrotność usługi w zamówieniu?">
            <b-checkbox v-model="variant.enable_counter">
              {{ variant.enable_counter ? "TAK" : "NIE" }}
            </b-checkbox>
          </b-field>
          <b-button
            type="is-primary"
            class="mr-3"
            rounded
            @click="editVariantService()"
          >
            Zapisz
          </b-button>
          <b-button type="is-dark" rounded @click="isEditVariantActive = false">
            Anuluj
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    service: Object,
  },
  data() {
    return {
      isCreatorVariantActive: false,
      isEditVariantActive: false,
      isDeleteVariantActive: false,
      variant: {},
    };
  },

  computed: {
    token: {
      get() {
        return this.$store.state.auth.token;
      },
    },
    id: {
      get() {
        return this.$store.state.auth.id;
      },
    },
  },
  methods: {
    addVariant: function (service) {
      //console.log(service);
      this.isCreatorVariantActive = true;
      this.variant = {
        name: "",
        description: "",
        value: "",
        working_days: "",
        enable_counter: false,
        order: 1,
        car_service_subtype_id: service.id,
      };
    },
    addVariantService: function () {
      this.$store
        .dispatch("serviceCreator/addService", {
          token: this.token,
          companyId: this.id,
          body: this.variant,
        })
        .then((response) => {
          response;
          //console.log(response);
        });
    },
    editVariant: function (variant) {
      this.isEditVariantActive = true;
      this.variant = variant;
    },
    editVariantService: function () {
      this.$store
        .dispatch("serviceCreator/editService", {
          token: this.token,
          companyId: this.id,
          serviceId: this.variant.id,
          body: this.variant,
        })
        .then((response) => {
          response;
          //console.log(response);
        });
    },
    deleteVariant: function (variant) {
      this.$emit("deleteVariant", variant);
    },
    removeSubService: function (id) {
      this.$emit("delete", id);
    },
  },
  created() {
    //console.log(this.service);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: $primary;
  p {
    color: #fff;
  }
}
.is-grey {
  color: #999;
  strong {
    word-break: break-all;
    color: #999;
  }
}
</style>