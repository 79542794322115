<template>
  <div class="block">
    <div class="block" v-if="services && services.length == 0">
      <p>Brak utworzonych usług</p>
    </div>
    <div v-else>
      <div class="mb-3">
        <div class="is-size-4">Oferowane usługi</div>
      </div>
      <div
        class="tile is-12 is-vertical"
        v-for="(type, index) in types"
        :key="index"
      >
        <div
          class="columns is-12 is-size-5 mt-6"
          v-if="filtered(type.id) && filtered(type.id).length > 0"
        >
          <strong>{{ type.name }}</strong>
        </div>
        <div class="columns is-desktop is-multiline">
          <div
            class="column is-one-third"
            v-for="(service, index2) in filtered(type.id)"
            :key="index2"
          >
            <service-details
              :service="service"
              @delete="remove"
              @deleteVariant="deleteVariant"
            ></service-details>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ServiceDetails from "@/components/company/ServiceDetails";
export default {
  components: {
    "service-details": ServiceDetails,
  },
  data() {
    return {
      isEditing: false,

      editorServices: {},
    };
  },

  methods: {
    filtered: function (id) {
      return this.services.filter((s) => {
        //console.log(s.service_type_id == id);
        return s.car_service_type_id == id;
      });
    },

    remove(value) {
      this.$buefy.dialog.confirm({
        title: "",
        message: "Czy na pewno chcesz usunąć oferowaną usługę?",
        confirmText: "Usuń",
        cancelText: "Anuluj",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => {
          this.$store
            .dispatch("serviceCreator/deleteSubtype", {
              token: this.token,
              companyId: this.id,
              subtypeId: value,
            })
            .then((value) => {
              if (value == true) this.$buefy.toast.open("Usługa usunięta");
              else this.$buefy.toast.open("Nie można usunąć usługi");
            });
        },
      });
    },
    deleteVariant(variant) {
      //console.log(variant);
      this.$buefy.dialog.confirm({
        title: "",
        message: `Czy na pewno chcesz usunąć wariant ${variant.name} usługi (${variant.value} zł)?`,
        confirmText: "Usuń",
        cancelText: "Anuluj",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => {
          this.$store
            .dispatch("serviceCreator/deleteService", {
              token: this.token,
              companyId: this.id,
              serviceId: variant.id,
            })
            .then((value) => {
              if (value == true) {
                //TODO KASOWANIE USŁUGI
                this.$buefy.toast.open("Usługa usunięta");
              } else this.$buefy.toast.open("Nie można usunąć usługi");
            });
        },
      });
    },
  },

  computed: {
    token: {
      get() {
        return this.$store.state.auth.token;
      },
    },
    id: {
      get() {
        return this.$store.state.auth.id;
      },
    },
    types: {
      get() {
        return this.$store.state.serviceCreator.types;
      },
    },
    services: {
      get() {
        return this.$store.state.serviceCreator.services;
      },
    },
  },
};
</script>