<template>
  <div>
    <div >
      <service-creator></service-creator>
    </div>
    <div class="list ml-5 mt-5">
      <services-listing></services-listing>
    </div>
  </div>
</template>
<script>
import ServicesListing from "@/components/company/ServicesListing";
import ServiceCreator from "@/components/company/ServiceCreator";
export default {
  components: {
    "services-listing": ServicesListing,
    "service-creator": ServiceCreator,
  },

  computed: {
    token: {
      get() {
        return this.$store.state.auth.token;
      },
    },
    id: {
      get() {
        return this.$store.state.auth.id;
      },
    },
  },

  created() {
    this.$store.dispatch("serviceCreator/servicesType");
    this.$store.dispatch("serviceCreator/companyServices", {
      token: this.token,
      companyId: this.id,
    });
  },
};
</script>
<style lang="scss" scoped>

</style>
